<template>
  <div class="about-us-page">
    <h2 class="mx-10"> About the center</h2>
    <vx-card>
      <div v-if="aboutContent">
        <div class="header-table">
          <div class="w-full mb-8">
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/4">
                <div class="card-title">
                  <h2>{{ aboutContent.title ? aboutContent.title : '' }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fullwidth-banner">
          <img :src="aboutContent.bannerPhoto ? aboutContent.bannerPhoto : $defaultImage" class="responsive">
        </div>
        <p class="w-full mt-5 mb-5 about-us" v-html="aboutContent.description ? aboutContent.description : '' "></p>
        <p class="h4 mt-5"><a :href="formattedUrl(aboutContent.websiteLink)" target="_blank"
                              class="underline">website</a></p>
      </div>
      <div v-else>
        <p>About us content is not available for current learning center.</p>

      </div>

    </vx-card>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import {formattedUrl} from "../../../helpers/general";

export default {
  data() {
    return {
      aboutContent: ''
    }
  },
  methods: {
    ...mapActions("aboutUs", [
      "fetchParentAboutUsByCenterId",
    ]),
    formattedUrl,
    async getAbout() {
      this.$vs.loading()
      await this.fetchParentAboutUsByCenterId(this.child.learningCenterId).then((response) => {
        this.$vs.loading.close();
        if (response.data.data) {
          this.aboutContent = response.data.data;
        } else {
          this.aboutContent = '';
        }
      }).catch((err) => {
        this.$vs.loading.close()
        console.log(err)
      });
    }
  },
  mounted() {
    if (this.child) {
      this.getAbout();
    } else {
      this.aboutContent = '';
    }
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem('child'));
    },
  },
}
</script>
